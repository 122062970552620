const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://sriramsub.com',
  title: 'SS',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Sriram Subramaniam',
  role: 'Software Engineer/Cybersecurity Enthusiast',
  // resume: '../RESUME-Sriram_Subramaniam.pdf',
  social: {
    linkedin: 'https://linkedin.com/in/sriramsub2802',
    github: 'https://github.com/spideysri2802',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'SUTD Project Showcase',
    description:
      'A platform to showcase SUTD students\' projects over the years.',
    stack: ['TailWindCSS', 'JavaScript', 'React'],
    // sourceCode: 'https://github.com',
    livePreview: 'https://sutdfolio.com/',
  },
  {
    name: 'Sharetings',
    description:
      'Sharetings is a sharing platform that makes giving and receiving of pre-loved items easy and free. It helps to reduce waste and saves the environment.',
    stack: ['Dart', 'Flutter'],
    // sourceCode: 'https://github.com',
    livePreview: 'https://sharetings.com',
  },
  // {
  //   name: 'Project 3',
  //   description:
  //     'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
  //   stack: ['SASS', 'TypeScript', 'React'],
  //   sourceCode: 'https://github.com',
  //   livePreview: 'https://github.com',
  // },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'TypeScript',
  'ReactJS',
  'Java',
  'Python',
  'Dart',
  'Flutter',
  'Material UI',
  'Git',
  'TailWind CSS',
  'Metasploit',
  'Ettercap',
  'Nmap',
  'Wireshark',
  'Kali Linux',
  'Swift',
  'Kotlin'
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'me@sriramsub.com',
}

export { header, about, projects, skills, contact }
